import { useState, useEffect } from "react";

export const useFetch = (url) => {
    const baseUrl = process.env.REACT_APP_API_URL;

    const [response, setResponse] = useState(undefined);
    const [loading, setLoading] = useState(true);

    const fetchData = async (finalUrl) => {
        fetch(finalUrl)
            .then((response) => response.json())
            .then((data) => {
                setResponse(data);
                setLoading(false);
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        fetchData(`${baseUrl}${url}`);
    }, [url]);

    return { response, loading, setLoading };
};
