import React from "react";
import { useFetch } from "../../../hooks/useFetch";

import MobileNav from "../../Navbar/MobileNav";
import TopNavBar from "../../Navbar/TopNavBar";
import Project from "./Project";
import Loading from "../../../loading/Loading";

const index = () => {
  const { response, loading } = useFetch("/project-list");

  return (
    <React.Fragment>
      <TopNavBar></TopNavBar>
      <h3 className="title_of_page">Projects</h3>

      {loading ? (
        <Loading />
      ) : (
        <section className="page_container">
          {response &&
            response.map((project) => {
              if (project.name === "" && project.githubURL === "") return null;
              return (
                project && (
                  <Project
                    key={project.id}
                    name={project.name}
                    coverURL={project.coverURL}
                    desc={project.description}
                    githubURL={project.githubURL}
                    previewURL={project.previewURL}
                    tools={project.tools}
                  />
                )
              );
            })}
        </section>
      )}

      <MobileNav></MobileNav>
    </React.Fragment>
  );
};

export default index;
