import React from "react";

import TopNavBar from "../../Navbar/TopNavBar";
import MobileNav from "../../Navbar/MobileNav";
import Contact from "./Contact";

const index = () => {
    return (
        <React.Fragment>
            <TopNavBar></TopNavBar> 
            <Contact></Contact>
            <MobileNav></MobileNav>
        </React.Fragment>
    );
};

export default index;
