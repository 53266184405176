import React from "react";

const Skill = (props) => {
    return (
        <div className="skill">
            <div className="image">
                <img src={props.icon} alt={props.name} width="65" height="auto" loading="lazy"/>
            </div>
            <div className="skill_info">
                <div className="name_and_level">
                    <p className="name">{props.name}</p>
                    <p className="level">{props.level ? props.level : "0%"}</p>
                </div>
                <div className="skill_bars">
                    <div
                        style={{
                            maxWidth: "100%",
                            width: props.level ? `${props.level}` : "10%",
                        }}
                        className="skill__bar"
                    ></div>
                    <div className="bg-bar"></div>
                </div>
            </div>
        </div>
    );
};

export default Skill;
