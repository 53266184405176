import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const index = () => {
  const currentRoute = window.location.pathname;
  const routes = ["/", "/skills", "/blogs", "/projects", "/contact"];

  useEffect(() => {
    // Gathering all the top-nav-links
    const navLinks = document.querySelectorAll(".top-nav-link");

    // Checking if the current route is valid
    if (routes.includes(currentRoute)) {
      // this checks the currentRoute index the return it the navLinks
      // then we change the style according to the index e.g. navLinks[0].style.color ="black";
      navLinks[routes.indexOf(currentRoute)].style.borderBottom =
        "4px solid black";
    }
  }, [currentRoute]);

  return (
    <div className="top__navbar">
      <div className="title">
        <a
          href="https://www.github.com/j471n"
          target="_blank"
          rel="noopener noreferrer"
        >
          Jatin Sharma
        </a>
      </div>

      <nav className="top__nav_list">
        <ul className="nav_list">
          <li className="top-nav-link">
            <Link to="/">Home</Link>
          </li>
          <li className="top-nav-link">
            <Link to="/skills">Skills</Link>
          </li>
          <li className="top-nav-link">
            <Link to="/blogs">Blogs</Link>
          </li>
          <li className="top-nav-link">
            <Link to="/projects">Projects</Link>
          </li>
          <li className="top-nav-link">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default index;
