import React from "react";
import { BsGithub } from "react-icons/bs";
import { FcLink } from "react-icons/fc";
import { BiShareAlt } from "react-icons/bi";
const Project = (props) => {
  async function handleShare() {
    const image = await fetch(props.coverURL);
    const blob = await image.blob();
    const file = new File([blob], "image.jpg", { type: "image/jpeg" });
    if (window.navigator.share) {
      window.navigator
        .share({
          title: props.name,
          text: props.desc || "",
          url: props.previewURL || props.githubURL,
          files: [file],
        })
        .then(() => {
          console.log("Thanks for sharing! Project");
        })
        .catch(console.error);
    }
  }


  return (
    <div className="project">
      {props.coverURL && (
        <div className="cover">
          <img src={props.coverURL} alt={props.name} loading="lazy"/>
        </div>
      )}

      {props.name && (
        <div className="info">
          {props.name && <h4 className="name">{props.name}</h4>}
          {props.desc && <p className="desc">{props.desc}</p>}
          {props.tools && (
            <p className="tools">
              {props.tools.map((tool) => {
                return (
                  <img
                    key={tool}
                    title={tool}
                    src={`img/skills/${tool}.webp`}
                    alt={tool}
                    width="30"
                    height="auto"
                    loading="lazy"
                  />
                );
              })}
            </p>
          )}
        </div>
      )}

      <div className="buttons">
        {props.githubURL && (
          <a
            title="Github"
            href={props.githubURL}
            target="blank"
            className="link"
          >
            <BsGithub />
          </a>
        )}
        {props.previewURL && (
          <a
            title="Visit"
            href={props.previewURL}
            target="blank"
            className="link"
          >
            <FcLink />
          </a>
        )}
        <div title="Share" className="link" onClick={handleShare}>
          <BiShareAlt />
        </div>
      </div>
    </div>
  );
};

export default Project;
