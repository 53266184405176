import React, { useEffect, useState, useRef } from "react";
import MobileNav from "../../Navbar/MobileNav";
import TopNavBar from "../../Navbar/TopNavBar";
import Blog from "./Blog";
import Loading from "../../../loading/Loading";

const index = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const state = useRef();
  const [sortBlogBy, setSortBlogBy] = useState("recent");

  const url = "https://dev.to/api/articles?username=j471n";

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data.sort());
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  // It sorts the blog based on date/ popularity
  function sortBy(e) {
    const sort_by = e.target.value;
    setSortBlogBy(sort_by);
    if (sort_by === "popular") {
      setBlogs(
        blogs
          .sort(
            (a, b) => a.positive_reactions_count - b.positive_reactions_count
          )
          .reverse()
      );
    } else if (sort_by === "recent") {
      setBlogs(
        blogs
          .sort(
            (a, b) =>
              new Date(a.published_timestamp) - new Date(b.published_timestamp)
          )
          .reverse()
      );
    }
  }

  return (
    <React.Fragment>
      <TopNavBar />

      {/* Selected option for the popular blog */}
      <div className="sort_by">
        <h3>Blogs</h3>
        <select ref={state} name="option" value={sortBlogBy} onChange={sortBy}>
          <option value="recent">Recent</option>
          <option value="popular">Popular</option>
        </select>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <section className="page_container">
          {blogs &&
            blogs.map((blog) => {
              return <Blog key={blog.id} blog={blog} />;
            })}
        </section>
      )}

      <MobileNav />
    </React.Fragment>
  );
};

export default index;
