import React from "react";
import MobileNav from "../../Navbar/MobileNav";
import TopNavBar from "../../Navbar/TopNavBar";

const index = () => {
    return (
        <React.Fragment>
            <TopNavBar></TopNavBar>
            <MobileNav></MobileNav>
        </React.Fragment>
    );
};

export default index;
