import React, { useState, useEffect } from "react";

import { FaShare } from "react-icons/fa";
import { FcLink } from "react-icons/fc";
function Blog({ blog }) {
  const [shareSupport, setShareSupport] = useState(false);

  useEffect(() => {
    window.navigator.share ? setShareSupport(true) : setShareSupport(false);
  }, []);

  async function handleShare() {
    const image = await fetch(blog.cover_image);
    const blob = await image.blob();
    const file = new File([blob], "image.jpg", { type: "image/jpeg" });
    if (window.navigator.share) {
      window.navigator
        .share({
          title: blog.title,
          text: blog.description,
          url: blog.url,
          files: [file],
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    }
  }

  return (
    <div className="blog">
      <img src={blog.cover_image} alt={blog.title} loading="lazy" />

      <div className="blogInfo">
        <div className="blog_user">
          <div className="user">
            <img src={blog.user.profile_image} alt="" width={40} height={40} loading="lazy"/>
            <a
              className="blog_details"
              href={`https://dev.to/${blog.user.username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="name">{blog.user.name}</p>
              <p className="blog-date">{blog.readable_publish_date}</p>
            </a>
          </div>

          {blog.public_reactions_count >= 50 && (
            <div className="hot_post">
              <img src="img/fire-icon.jpg" alt="" height={20} loading="lazy"/>
              <p>Hot</p>
            </div>
          )}
        </div>

        <div className="blog_info">
          <h3>{blog.title}</h3>
          <p>{blog.description}</p>

          <div className="tags">
            {blog.tag_list.map((tag) => {
              return (
                <p key={tag} className="tag">
                  #{tag}
                </p>
              );
            })}
          </div>

          <div className="blog_bottom">
            <div className="blog_vote">
              <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                className="icon"
              >
                <path d="M13.162 3.813a2 2 0 01.465.465l6.674 9.343a1 1 0 01-1.102 1.539l-4.032-1.21a1 1 0 00-1.277.816l-.767 5.375a1 1 0 01-.99.859h-.266a1 1 0 01-.99-.859l-.767-5.375a1 1 0 00-1.278-.816l-4.031 1.21a1 1 0 01-1.102-1.54l6.674-9.342a2 2 0 012.79-.465z"></path>
              </svg>
              <p>{blog.public_reactions_count}</p>
            </div>

            <div className="blog_url">
              {shareSupport && (
                <FaShare className="shareIcon" onClick={handleShare} />
              )}
              <FcLink onClick={() => window.open(blog.url)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
