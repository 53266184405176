import "./App.css";
import React from "react";

// Importing Components
import Home from "./components/pages/Home";
import Skills from "./components/pages/Skills";
import Blogs from "./components/pages/Blogs";
import Projects from "./components/pages/Projects";
import Contact from "./components/pages/Contact";

import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home></Home>
        </Route>
        <Route exact path="/skills">
          <Skills></Skills>
        </Route>
        <Route exact path="/blogs">
          <Blogs></Blogs>
        </Route>
        <Route exact path="/projects">
          <Projects></Projects>
        </Route>
        <Route exact path="/contact">
          <Contact></Contact>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
