import React, { useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import Loading from "../../../loading/Loading";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const initialFormState = {
  to_name: "Jatin Sharma",
  from_name: "",
  email: "",
  subject: "",
  message: "",
};

const Contact = () => {
  const { response, loading, setLoading } = useFetch("/contact-info");

  const [emailInfo, setEmailInfo] = useState(initialFormState);

  function sendEmail(e) {
    e.preventDefault();

    setLoading(true);
    emailjs
      .send(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID,
        emailInfo,
        process.env.REACT_APP_YOUR_USER_ID
      )
      .then((res) => {
        console.log("Email Sent Successfully");
        setLoading(false);
        setEmailInfo(initialFormState);
        toast.success("Message Sent ✌");
      })
      .catch((err) => {
        console.log(err.text);
        setLoading(false);
        toast.error("😢 "+err.text);
      });
  }

  return (
    <section className="contact">
      <h3 className="title_of_page">Contact Me!</h3>
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <div className="profile">
            <img
              src="img/profile.jpg"
              alt="Jatin Sharma"
              width="100"
              height="100"
              loading="lazy"
            />
          </div>

          <form className="contact__form" onSubmit={sendEmail}>
            <input
              className="field"
              value={emailInfo.from_name}
              type="text"
              name="from_name"
              placeholder="Name"
              required
              onChange={(e) =>
                setEmailInfo({
                  ...emailInfo,
                  [e.target.name]: e.target.value,
                })
              }
            />
            <input
              className="field"
              value={emailInfo.email}
              type="email"
              name="email"
              placeholder="example@gmail.com"
              required
              onChange={(e) =>
                setEmailInfo({
                  ...emailInfo,
                  [e.target.name]: e.target.value,
                })
              }
            />
            <input
              className="field"
              value={emailInfo.subject}
              type="text"
              name="subject"
              placeholder="Subject"
              required
              onChange={(e) =>
                setEmailInfo({
                  ...emailInfo,
                  [e.target.name]: e.target.value,
                })
              }
            />
            <textarea
              className="field"
              name="message"
              value={emailInfo.message}
              placeholder="Message"
              required
              onChange={(e) =>
                setEmailInfo({
                  ...emailInfo,
                  [e.target.name]: e.target.value,
                })
              }
            ></textarea>
            <input className="sendBtn" type="submit" value="Send" />
          </form>

          <div className="social__icons">
            <h3>Social Media</h3>

            <div className="icons">
              {response &&
                response.map((item) => {
                  return (
                    <a
                      key={item.name}
                      href={item.link}
                      target="_blank"
                      title={item.name}
                      rel="noopener noreferrer"
                    >
                      <img
                        className={`icon ${item.name}`}
                        src={item.src}
                        alt={item.name}
                        width="50"
                        height="50"
                        loading="lazy"
                      />
                    </a>
                  );
                })}
            </div>
          </div>

          <div className="buy-me-a-coffee">
            <a
              href="https://www.buymeacoffee.com/j471n"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://www.buymeacoffee.com/assets/img/guidelines/download-assets-2.svg"
                alt="buy-me-a-coffee"
                width="100"
                height="auto"
                loading="lazy"
              />
            </a>
          </div>
        </React.Fragment>
      )}
      <ToastContainer />
    </section>
  );
};

export default Contact;
