import React from "react";
import LoadingImg from "./loading.svg";

const Loading = ({ count }) => {
    return (
      <div className="loading">
        <img src={LoadingImg} alt="" loading="lazy" />
      </div>
    );
};

export default Loading;
