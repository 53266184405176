import React, { useEffect, useState } from "react";

import {
    AiOutlineHome,
    AiOutlineProject,
    AiOutlinePhone,
    AiFillProject,
    AiFillHome,
    AiFillPhone,
} from "react-icons/ai";
// import {  } from "react-icons/ai";
// import {  } from "react-icons/ai";
import { RiArticleLine, RiArticleFill } from "react-icons/ri";
import { BsBarChart, BsBarChartFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const index = () => {

    const [routes, setRoutes] = useState({
        "/": false,
        "/skills": false,
        "/blogs": false,
        "/projects": false,
        "/contact": false,
    });

    const currentRoute = window.location.pathname;
    useEffect(() => {
        for (let route in routes) {
            routes[route] = false;
        }
        if (routes.hasOwnProperty(currentRoute)) {
            let obj = {}
            obj[currentRoute] = true;
            setRoutes({ ...routes, ...obj });
        }
    }, [currentRoute]);

    return (
        <div className="bottom__nav">
            <div className="bottom__nav_icon">
                <Link to="/">
                    {routes["/"] ? (
                        <AiFillHome className="icon" />
                    ) : (
                        <AiOutlineHome className="icon" />
                    )}
                </Link>
                <Link to="/skills">
                    {routes["/skills"] ? (
                        <BsBarChartFill className="icon" />
                    ) : (
                        <BsBarChart className="icon" />
                    )}
                </Link>
                <Link to="/blogs">
                    {routes["/blogs"] ? (
                        <RiArticleFill className="icon" />
                    ) : (
                        <RiArticleLine className="icon" />
                    )}
                </Link>
                <Link to="/projects">
                    {routes["/projects"] ? (
                        <AiFillProject className="icon" />
                    ) : (
                        <AiOutlineProject className="icon" />
                    )}
                </Link>
                <Link to="/contact">
                    {routes["/contact"] ? (
                        <AiFillPhone className="icon" />
                    ) : (
                        <AiOutlinePhone className="icon" />
                    )}
                </Link>
            </div>
        </div>
    );
};

export default index;
