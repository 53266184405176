import React from "react";
import MobileNav from "../../Navbar/MobileNav";
import TopNavBar from "../../Navbar/TopNavBar";
import Skill from "./Skill";
import Loading from "../../../loading/Loading";
import { useFetch } from "../../../hooks/useFetch";

const index = () => {

    const { response, loading } = useFetch("/skills")
    return (
        <React.Fragment>
            <TopNavBar></TopNavBar>
            <MobileNav></MobileNav>
            <h3 className="title_of_page">Skills</h3>
            {loading ? (
                <Loading />
            ) : (
                <section className="page_container">
                    {response.map((skill) => {
                        return (
                            <Skill
                                key={skill.id}
                                id={skill.id}
                                name={skill.name}
                                level={skill.level}
                                icon={skill.icon}
                            />
                        );
                    })}
                </section>
            )}
        </React.Fragment>
    );
};

export default index;
